import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"


export default function Page({ data }) {
  const post = data.markdownRemark
  return (
    <Layout>
      <div className="bg-rearview-mirror bg-cover bg-bottom">
        <div className="bg-black bg-opacity-50 pb-4">
          <div className="relative px-4 2xl:px-0 text-white container mx-auto md:flex items-center gap-8">
                  <div className="w-full md:w-1/2 lg:w-1/2 2xl:w-1/3 py-16 lg:py-36">
                      <h1 className="uppercase w-1/2 xl:w-full xl:text-7xl font-black f-f-l text-5xl">{post.frontmatter.title}</h1>
                      <div className="pb-20 sm:pb-0 pt-10 xl:pt-20">
                          <h2 className="font-normal text-white text-xl lg:text-3xl mb-4">{post.frontmatter.tagline}</h2>
                      </div>
                      <a target="_blank" rel="noreferrer" href="https://www.intaker.com/bartlettlegalgroup/ct-truck-accident"><button className="rounded hover:opacity-90 text-xl w-full xl:text-3xl xl:w-11/12 mt-4 xl:mt-11 f-f-r py-6 bg-green-400 text-white font-bold">Free Consultation</button></a>
                  </div>
            </div>
          </div>
      </div>
      <div id="content" dangerouslySetInnerHTML={{ __html: post.html }} />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title,
        tagline
      }
    }
  }
`